import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import ButtonTable from '../common/table/buttonTable';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';

import { setModoTela, initForm, getListaApuracao, excluirInadimplencia, excluirClienteNovo, excluirClienteRetomada } from './metaVendaTrimestreActions';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import FormatUtils from '../common/formatUtils/FormatUtils';

class MetaVendaTrimestreForm extends Component {

	state = {
		nome_vendedor: null,
		codigo_vendedor: null
	}

    componentWillMount() {
		this.props.getListaApuracao();
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let apuracao = this.props.listaApuracao.filter(item => item.id == this.props.formularioValues.id)[0];

        return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>

								<Field
									name='ano'
									component={Select}
									options={[{ id: 2024, valor: 2024 }, { id: 2025, valor: 2025 }, { id: 2026, valor: 2026 }]}
									label='Ano'
									cols='12 4 3 2'
									placeholder='Selecione o ano'
									readOnly={readOnly} />
									
								<Field
									name='trimestre'
									component={Select}
									options={[{ id: 1, valor: '1º trimestre' }, { id: 2, valor: '2º trimestre' }, { id: 3, valor: '3º trimestre' }, { id: 4, valor: '4º trimestre' } ]}
									label='Trimestre'
									cols='12 4 3 2'
									placeholder='Selecione o trimestre'
									readOnly={readOnly} />

								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome' placeholder='Informe'
									cols='12 6 4 2'
									readOnly={readOnly} />

								<Field
									name='geral'
									component={LabelAndCheckbox}
									label='Geral' placeholder='Informe'
									cols='12 6 4 2'
									readOnly={readOnly} />
								
								<Grid cols='12 12 12 12'>
									<label>Nome do Vendedor</label>
									<div class="input-group mb-3">
										<input 
											type='text'
											class='form-control'
											placeholder='Informe o nome e clique no (+)'
											value={this.state.nome_vendedor}
											onChange={data => this.setState({ ...this.state, nome_vendedor: data.target.value })} />
										<div class='input-group-append'>
											{(this.state.nome_vendedor || '').trim().length > 0 &&
											<button
												type='button'
												class='btn btn-primary'
												onClick={() => {

													let lista = (this.props.formularioValues.lista_vendedor_nomes || []).map(item => item);
													lista.push(this.state.nome_vendedor);

													this.props.initForm({
														...this.props.formularioValues,
														lista_vendedor_nomes: lista
													});

													this.setState({ ...this.state, nome_vendedor: '' });

												}}>
												<i class='fas fa-plus'></i>
											</button>}
										</div>
									</div>
								</Grid>
								<Grid cols='12 12 12 12'>
									<div
										style={{
											display: 'flex',
											flexDirection: 'rpw',
											flexWrap: 'wrap'
										}}>
										{(this.props.formularioValues.lista_vendedor_nomes || []).map(item => (
											<div key={item}
												style={{
													color: '#ffffff',
													backgroundColor: '#b0b0b0',
													paddingLeft: 16,
													paddingRight: 16,
													paddingTop: 4,
													paddingBottom: 4,
													borderRadius: 40,
													width: 'fit-content',
													marginRight: 4,
													marginBottom: 16
												}}>
												<label>{item}</label>
												<button
													type='button'
													class='btn btn-danger'
													onClick={() => {

														let lista = (this.props.formularioValues.lista_vendedor_nomes || []).filter(itemAux => itemAux != item);

														this.props.initForm({
															...this.props.formularioValues,
															lista_vendedor_nomes: lista
														});

													}}>
													<i class='fas fa-trash'></i>
												</button>
											</div>
										))}
									</div>
								</Grid>

								<Grid cols='12 12 12 12'>
									<label>Código do Vendedor</label>
									<div class="input-group mb-3">
										<input 
											type='text'
											class='form-control'
											placeholder='Informe o código e clique no (+)'
											value={this.state.codigo_vendedor}
											onChange={data => this.setState({ ...this.state, codigo_vendedor: data.target.value })} />
										<div class='input-group-append'>
											{(this.state.codigo_vendedor || '').trim().length > 0 &&
											<button
												type='button'
												class='btn btn-primary'
												onClick={() => {

													let lista = (this.props.formularioValues.lista_vendedor_codigos || []).map(item => item);
													lista.push(this.state.codigo_vendedor);

													this.props.initForm({
														...this.props.formularioValues,
														lista_vendedor_codigos: lista
													});

													this.setState({ ...this.state, codigo_vendedor: '' });

												}}>
												<i class='fas fa-plus'></i>
											</button>}
										</div>
									</div>
								</Grid>
								<Grid cols='12 12 12 12'>
									<div
										style={{
											display: 'flex',
											flexDirection: 'rpw',
											flexWrap: 'wrap'
										}}>
										{(this.props.formularioValues.lista_vendedor_codigos || []).map(item => (
											<div key={item}
												style={{
													color: '#ffffff',
													backgroundColor: '#b0b0b0',
													paddingLeft: 16,
													paddingRight: 16,
													paddingTop: 4,
													paddingBottom: 4,
													borderRadius: 40,
													width: 'fit-content',
													marginRight: 4,
													marginBottom: 16
												}}>
												<label>{item}</label>
												<button
													type='button'
													class='btn btn-danger'
													onClick={() => {

														let lista = (this.props.formularioValues.lista_vendedor_codigos || []).filter(itemAux => itemAux != item);

														this.props.initForm({
															...this.props.formularioValues,
															lista_vendedor_codigos: lista
														});

													}}>
													<i class='fas fa-trash'></i>
												</button>
											</div>
										))}
									</div>
								</Grid>

							</Row>
							<Row>
								<Field
									name='volume_1'
									component={LabelAndInputNumber}
									label='Volume (faixa 1)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='volume_2'
									component={LabelAndInputNumber}
									label='Volume (faixa 2)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='volume_3'
									component={LabelAndInputNumber}
									label='Volume (faixa 3)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='volume_4'
									component={LabelAndInputNumber}
									label='Volume (faixa 4)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='volume_5'
									component={LabelAndInputNumber}
									label='Volume (faixa 5)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='margem_1'
									component={LabelAndInputNumber}
									label='Margem (faixa 1)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='margem_2'
									component={LabelAndInputNumber}
									label='Margem (faixa 2)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='margem_3'
									component={LabelAndInputNumber}
									label='Margem (faixa 3)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='margem_4'
									component={LabelAndInputNumber}
									label='Margem (faixa 4)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='margem_5'
									component={LabelAndInputNumber}
									label='Margem (faixa 5)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='prazo_1'
									component={LabelAndInputNumber}
									label='Prazo (faixa 1)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='prazo_2'
									component={LabelAndInputNumber}
									label='Prazo (faixa 2)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='prazo_3'
									component={LabelAndInputNumber}
									label='Prazo (faixa 3)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='prazo_4'
									component={LabelAndInputNumber}
									label='Prazo (faixa 4)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='prazo_5'
									component={LabelAndInputNumber}
									label='Prazo (faixa 5)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
							</Row>

							{/*
							<Row>
								<Field
									name='inadimplencia_maxima'
									component={LabelAndInputNumber}
									label='Inadimplência Máxima' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='inadimplencia_perda'
									component={LabelAndInputNumber}
									label='Inadimplência Perda (%)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
							
							</Row>
							*/}

							<Row>
								<Field
									name='inadimplencia_1_maxima'
									component={LabelAndInputNumber}
									label='Inadimplência Máxima 1' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />
								
								<Field
									name='inadimplencia_1_perda'
									component={LabelAndInputNumber}
									label='Inadimplência Perda 1 (%)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />

								{this.props.formularioValues.geral &&
								<Field
									name='inadimplencia_2_maxima'
									component={LabelAndInputNumber}
									label='Inadimplência Máxima 2' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />}
								
								{this.props.formularioValues.geral &&
								<Field
									name='inadimplencia_2_perda'
									component={LabelAndInputNumber}
									label='Inadimplência Perda 2 (%)' placeholder='Informe'
									cols='12 6 4 2'
									casas={2}
									readOnly={readOnly} />}
							
							</Row>

							<Row>
								<Field
									name='cliente_retomada_1'
									component={LabelAndInputNumber}
									label='Cliente - Retomada (faixa 1)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							
								<Field
									name='cliente_retomada_2'
									component={LabelAndInputNumber}
									label='Cliente - Retomada (faixa 2)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							
								<Field
									name='cliente_retomada_3'
									component={LabelAndInputNumber}
									label='Cliente - Retomada (faixa 3)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							
								<Field
									name='cliente_retomada_4'
									component={LabelAndInputNumber}
									label='Cliente - Retomada (faixa 4)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							
								<Field
									name='cliente_retomada_5'
									component={LabelAndInputNumber}
									label='Cliente - Retomada (faixa 5)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							</Row>

							<Row>
								<Field
									name='cliente_novo_1'
									component={LabelAndInputNumber}
									label='Cliente - Novo (faixa 1)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							
								<Field
									name='cliente_novo_2'
									component={LabelAndInputNumber}
									label='Cliente - Novo (faixa 2)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							
								<Field
									name='cliente_novo_3'
									component={LabelAndInputNumber}
									label='Cliente - Novo (faixa 3)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							
								<Field
									name='cliente_novo_4'
									component={LabelAndInputNumber}
									label='Cliente - Novo (faixa 4)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							
								<Field
									name='cliente_novo_5'
									component={LabelAndInputNumber}
									label='Cliente - Novo (faixa 5)' placeholder='Informe'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />
							</Row>

						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>

					</Form>
				</ContentCard>

				{(apuracao && apuracao.inadimplencia_itens) &&
				<ContentCard>
					<ContentCardHeader>
						<h5>Inadimplência</h5>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th alignLeft>Cliente</Th>
									<Th alignCenter>Valor</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{apuracao.inadimplencia_itens.map(item => (
									<Tr key={item.ids}>
										<Td alignLeft>{item.nome_cliente}</Td>
										<Td alignCenter>{FormatUtils.formatarValorTelaDecimal(item.valor, 2)}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												event={() => {
													if (window.confirm('Deseja realmente excluir?')) {
														this.props.excluirInadimplencia(item);
													}
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>}

				{(apuracao && apuracao.clientes_novos
					&& (
						this.props.formularioValues.cliente_novo_1 > 0
						|| this.props.formularioValues.cliente_novo_2 > 0
						|| this.props.formularioValues.cliente_novo_3 > 0
						|| this.props.formularioValues.cliente_novo_4 > 0
						|| this.props.formularioValues.cliente_novo_5 > 0
					)) &&
				<ContentCard>
					<ContentCardHeader>
						<h5>Clientes - Novos</h5>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th alignCenter>ID</Th>
									<Th alignCenter>CFP/CNPJ</Th>
									<Th alignCenter>Cliente</Th>
									<Th alignCenter>IE</Th>
									<Th alignCenter>Endereço</Th>
									<Th alignCenter>Quatidade</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{apuracao.clientes_novos.map(item => (
									<Tr key={item.ids}>
										<Td alignCenter>{item.id_petroshow}</Td>
										<Td alignCenter>{item.cpf_cnpj}</Td>
										<Td alignCenter>{item.nome_cliente}</Td>
										<Td alignCenter>{item.inscricao_estadual}</Td>
										<Td alignCenter>{item.endereco}</Td>
										<Td alignCenter>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 0)}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												event={() => {
													if (window.confirm('Deseja realmente excluir?')) {
														this.props.excluirClienteNovo(item);
													}
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>}

				{(apuracao && apuracao.clientes_retomados
					&& (
						this.props.formularioValues.cliente_retomada_1 > 0
						|| this.props.formularioValues.cliente_retomada_2 > 0
						|| this.props.formularioValues.cliente_retomada_3 > 0
						|| this.props.formularioValues.cliente_retomada_4 > 0
						|| this.props.formularioValues.cliente_retomada_5 > 0
					)) &&
				<ContentCard>
					<ContentCardHeader>
						<h5>Clientes - Retomada</h5>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th alignCenter>ID</Th>
									<Th alignCenter>CFP/CNPJ</Th>
									<Th alignCenter>Cliente</Th>
									<Th alignCenter>IE</Th>
									<Th alignCenter>Endereço</Th>
									<Th alignCenter>Quatidade</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{apuracao.clientes_retomados.map(item => (
									<Tr key={item.ids}>
										<Td alignCenter>{item.id_petroshow}</Td>
										<Td alignCenter>{item.cpf_cnpj}</Td>
										<Td alignCenter>{item.nome_cliente}</Td>
										<Td alignCenter>{item.inscricao_estadual}</Td>
										<Td alignCenter>{item.endereco}</Td>
										<Td alignCenter>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 0)}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												event={() => {
													if (window.confirm('Deseja realmente excluir?')) {
														this.props.excluirClienteRetomada(item);
													}
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>}
			</>
        )
    }


}

MetaVendaTrimestreForm = reduxForm({form: 'metaVendaTrimestreForm', destroyOnUnmount: false})(MetaVendaTrimestreForm);
const mapStateToProps = state => ({
	sessao: state.auth.metaVendaTrimestre,
	formularioValues: getFormValues('metaVendaTrimestreForm')(state),
	registro: state.metaVendaTrimestre.registro,
	listaUsuario: state.metaVendaTrimestre.listaUsuario.filter(item => item.administrador),
	listaApuracao: state.metaVendaTrimestre.listaApuracao
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getListaApuracao, excluirInadimplencia, excluirClienteNovo, excluirClienteRetomada }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVendaTrimestreForm);
