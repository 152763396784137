import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemUser from '../assets/images/user.jpg';
import { setError, setSuccess } from '../common/toast/toast';
import contentCardBody from '../common/template/contentCardBody';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Cookies from 'react-cookies';

import { 
	getListaCustoFolha, getListaCustoFrota, getListaCustoOutrasDespesas,
	getListaCustoAbastecida, getListaKmRodado, 
	salvarCustoFolha, excluirCustoFolha, getListaVenda 
} from './dashboardCustoActions';

const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

class DashboardCusto extends Component {

	state = {
		pesquisar: '',
		ano: new Date().getFullYear(),
		meses: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
		custoFrota: true,
		custoAbastecida: true,
		custoFolha: true,
		custoOutros: true,
		totalKmRodado: true,
		mediaKmRodado: true,
		custoFrotaKmRodado: true,
		custoAbastecidaKmRodado: true,
		vendasValor: true,
		vendasQuantidade: true,
		custoLitroVenda: true,
		modalCustoFolha: null
	}

	constructor(props) {
		super(props);
    }

    componentWillMount() {
		this.props.getListaCustoFolha();
		this.props.getListaCustoFrota();
		this.props.getListaCustoOutrasDespesas();
		this.props.getListaCustoAbastecida();
		this.props.getListaKmRodado();
		this.props.getListaVenda();
    
		let idInterval = setInterval(() => {
			this.props.getListaCustoFolha();
			this.props.getListaCustoFrota();
			this.props.getListaCustoOutrasDespesas();
			this.props.getListaCustoAbastecida();
			this.props.getListaKmRodado();
			this.props.getListaVenda();
		}, 30000);

		const cookieFiltro = Cookies.load('dashboardCustosFiltro');
		if (!cookieFiltro) {
			Cookies.save('dashboardCustosFiltro', JSON.stringify({
				ano: this.state.ano,
				meses: this.state.meses,
				custoFrota: this.state.custoFrota,
				custoAbastecida: this.state.custoAbastecida,
				custoFolha: this.state.custoFolha,
				custoOutros: this.state.custoOutros,
				totalKmRodado: this.state.totalKmRodado,
				mediaKmRodado: this.state.mediaKmRodado,
				custoFrotaKmRodado: this.state.custoFrotaKmRodado,
				custoAbastecidaKmRodado: this.state.custoAbastecidaKmRodado,
				vendasValor: this.state.vendasValor,
				vendasQuantidade: this.state.vendasQuantidade,
				custoLitroVenda: this.state.custoLitroVenda
			}), { path: '/' });

			this.setState({
				...this.state,
				idInteral: idInterval
			});
		} else {
			console.log(cookieFiltro);
			this.setState({
				...this.state,
				idInteral: idInterval,
				ano: cookieFiltro ? cookieFiltro.ano || this.state.ano : this.state.ano,
				meses: cookieFiltro ? cookieFiltro.meses || this.state.meses : this.state.meses,
				custoFrota: cookieFiltro ? cookieFiltro.custoFrota : true,
				custoAbastecida: cookieFiltro ? cookieFiltro.custoAbastecida : true,
				custoFolha: cookieFiltro ? cookieFiltro.custoFolha : true,
				custoOutros: cookieFiltro ? cookieFiltro.custoOutros : true,
				totalKmRodado: cookieFiltro ? cookieFiltro.totalKmRodado : true,
				mediaKmRodado: cookieFiltro ? cookieFiltro.mediaKmRodado : true,
				custoFrotaKmRodado: cookieFiltro ? cookieFiltro.custoFrotaKmRodado : true,
				custoAbastecidaKmRodado: cookieFiltro ? cookieFiltro.custoAbastecidaKmRodado : true,
				vendasValor: cookieFiltro ? cookieFiltro.vendasValor : true,
				vendasQuantidade: cookieFiltro ? cookieFiltro.vendasQuantidade : true,
				custoLitroVenda: cookieFiltro ? cookieFiltro.custoLitroVenda : true
			});
		}
	}

	componentWillUnmount() {
		
		clearInterval(this.state.idInteral);
		
	}

	validaCompetencia(competencia) {

		return (this.state.meses || []).map(mes => parseInt(`${this.state.ano}${mes}`)).includes(parseInt(competencia));
	}

	atualizarCookie(filtro) {
		Cookies.save('dashboardCustosFiltro', JSON.stringify({
			ano: filtro.ano,
			meses: filtro.meses,
			custoFrota: filtro.custoFrota,
			custoAbastecida: filtro.custoAbastecida,
			custoFolha: filtro.custoFolha,
			custoOutros: filtro.custoOutros,
			totalKmRodado: filtro.totalKmRodado,
			mediaKmRodado: filtro.mediaKmRodado,
			custoFrotaKmRodado: filtro.custoFrotaKmRodado,
			custoAbastecidaKmRodado: filtro.custoAbastecidaKmRodado,
			vendasValor: filtro.vendasValor,
			vendasQuantidade: filtro.vendasQuantidade,
			custoLitroVenda: filtro.custoLitroVenda
		}), { path: '/' });

		this.setState({
			...filtro
		});
	}

    render() {

		const listaCustoFrota = this.props.listaCustoFrota.filter(item => this.validaCompetencia(item.competencia));
		const listaCustoAbastecida = this.props.listaCustoAbastecida.filter(item => this.validaCompetencia(item.competencia));
		const listaCustoFolha = this.props.listaCustoFolha.filter(item => this.validaCompetencia(item.competencia));
		const listaCustoOutrasDespesas = this.props.listaCustoOutrasDespesas.filter(item => this.validaCompetencia(item.competencia));
		const listaKmRodado = this.props.listaKmRodado.filter(item => this.validaCompetencia(item.competencia));
		const listaVenda = this.props.listaVenda.filter(item => this.validaCompetencia(item.competencia));

		let valorTotalFrota = 0;
		let maiorValorFrota = 0;
		listaCustoFrota.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).forEach(item => {
			valorTotalFrota += parseFloat(item.valor);
			maiorValorFrota = parseFloat(item.valor) > maiorValorFrota ? parseFloat(item.valor) : maiorValorFrota;
		});

		let valorTotalAbastecida = 0;
		let maiorValorAbastecida = 0;
		listaCustoAbastecida.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).forEach(item => {
			valorTotalAbastecida += parseFloat(item.valor);
			maiorValorAbastecida = parseFloat(item.valor) > maiorValorAbastecida ? parseFloat(item.valor) : maiorValorAbastecida;
		});
		
		let valorTotalFolha = 0;
		let maiorValorFolha = 0;
		listaCustoFolha.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).forEach(item => {
			valorTotalFolha += parseFloat(item.valor);
			maiorValorFolha = parseFloat(item.valor) > maiorValorFolha ? parseFloat(item.valor) : maiorValorFolha;
		});

		let valorTotalOutros = 0;
		let maiorValorOutros = 0;
		listaCustoOutrasDespesas.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).forEach(item => {
			valorTotalOutros += parseFloat(item.valor);
			maiorValorOutros = parseFloat(item.valor) > maiorValorOutros ? parseFloat(item.valor) : maiorValorOutros;
		});

		let valorTotalKmRodado = 0;
		let maiorKmRodado = 0;
		listaKmRodado.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).forEach(item => {
			valorTotalKmRodado += parseFloat(item.km_rodado_total);
			maiorKmRodado = parseFloat(item.km_rodado_total) > maiorKmRodado ? parseFloat(item.km_rodado_total) : maiorKmRodado;
		});

		let valorTotalKmRodadoMedia = 0;
		let maiorKmRodadoMedia = 0;
		let quantidadeMesesKmRodadoMedia = 0;
		listaKmRodado.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).forEach(item => {
			//Se estamos no mês atual projeta a km pra não distorcer a média
			if (item.competencia == `${new Date().getFullYear()}${String(new Date().getMonth() + 1).padStart(2, '0')}`) {
				
				const diasMes = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
				const diaAtual = new Date().getDate();

				valorTotalKmRodadoMedia += (parseFloat(item.km_rodado_media) * diasMes) / diaAtual;
			} else {
				valorTotalKmRodadoMedia += parseFloat(item.km_rodado_media);
			}

			quantidadeMesesKmRodadoMedia++;
			
			maiorKmRodadoMedia = parseFloat(item.km_rodado_media) > maiorKmRodadoMedia ? parseFloat(item.km_rodado_media) : maiorKmRodadoMedia;
		});

		valorTotalKmRodadoMedia = quantidadeMesesKmRodadoMedia ? valorTotalKmRodadoMedia / quantidadeMesesKmRodadoMedia : 0;

		let custoTotalCustoFrotaKmRodado = 0;
		let custoTotalFrotaKmRodado = 0;
		let maiorCustoFrotaKmRodado = 0;

		let custoTotalCustoAbastecidaKmRodado = 0;
		let custoTotalAbastecidaKmRodado = 0;
		let maiorCustoAbastecidaKmRodado = 0;
		listaKmRodado.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).forEach(item => {

			const custoFrota = listaCustoFrota.filter(itemCusto => itemCusto.competencia == item.competencia)[0];						
			const custoFrotaMes = parseFloat(custoFrota ? custoFrota.valor : 0);
			const custoFrotaKmRodado = parseFloat(item.km_rodado_total) > 0 ? custoFrotaMes / parseFloat(item.km_rodado_total) : 0;

			custoTotalCustoFrotaKmRodado += custoFrotaMes;
			custoTotalFrotaKmRodado += parseFloat(item.km_rodado_total);
			maiorCustoFrotaKmRodado = custoFrotaKmRodado > maiorCustoFrotaKmRodado ? custoFrotaKmRodado : maiorCustoFrotaKmRodado;

			const custoAbastecida = listaCustoAbastecida.filter(itemCusto => itemCusto.competencia == item.competencia)[0];
			const custoAbastecidaMes = parseFloat(custoAbastecida ? custoAbastecida.valor : 0);
			const custoAbastecidaKmRodado = parseFloat(item.km_rodado_total) > 0 ? custoAbastecidaMes / parseFloat(item.km_rodado_total) : 0;

			custoTotalCustoAbastecidaKmRodado += custoAbastecidaMes;
			custoTotalAbastecidaKmRodado += parseFloat(item.km_rodado_total);
			maiorCustoAbastecidaKmRodado = custoAbastecidaKmRodado > maiorCustoAbastecidaKmRodado ? custoAbastecidaKmRodado : maiorCustoAbastecidaKmRodado;
		});

		let valorTotalCustoFrotaKmRodado = custoTotalFrotaKmRodado > 0 ? custoTotalCustoFrotaKmRodado / custoTotalFrotaKmRodado : 0;
		let valorTotalCustoAbastecidaKmRodado = custoTotalAbastecidaKmRodado > 0 ? custoTotalCustoAbastecidaKmRodado / custoTotalAbastecidaKmRodado : 0;

		let valorTotalVenda = 0;
		let maiorValorVenda = 0;
		let quantidadeTotalVenda = 0;
		let maiorQuantidadeVenda = 0;
		listaVenda.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).forEach(item => {
			valorTotalVenda += parseFloat(item.valor);
			maiorValorVenda = parseFloat(item.valor) > maiorValorVenda ? parseFloat(item.valor) : maiorValorVenda;

			quantidadeTotalVenda += parseFloat(item.quantidade);
			maiorQuantidadeVenda = parseFloat(item.quantidade) > maiorQuantidadeVenda ? parseFloat(item.quantidade) : maiorQuantidadeVenda;
		});

		let listaCustoLitroVenda = [];
		let valorTotalCustoVenda = 0;
		let valorTotalValorVenda = 0;
		let maiorCustoLitroVenda = 0;
		listaVenda.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).forEach(item => {
			const custoFrota = listaCustoFrota.filter(custo => custo.competencia == item.competencia)[0] || {};
			const custoAbastecida = listaCustoAbastecida.filter(custo => custo.competencia == item.competencia)[0] || {};
			const custoFolha = listaCustoFolha.filter(custo => custo.competencia == item.competencia)[0] || {};
			const custoOutros = listaCustoOutrasDespesas.filter(custo => custo.competencia == item.competencia)[0] || {};
			const custoTotal = parseFloat(custoFrota.valor || 0) 
				+ parseFloat(custoAbastecida.valor || 0)
				+ parseFloat(custoFolha.valor || 0)
				+ parseFloat(custoOutros.valor || 0);
			
			if (parseFloat(item.quantidade || 0) > 0 && custoTotal > 0) {
				const custoLitroVenda = custoTotal / parseFloat(item.quantidade || 0);

				listaCustoLitroVenda.push({
					competencia: item.competencia,
					valor: custoLitroVenda
				});

				maiorCustoLitroVenda = custoLitroVenda > maiorCustoLitroVenda ? custoLitroVenda : maiorCustoLitroVenda;
				valorTotalCustoVenda += custoTotal;
				valorTotalValorVenda += parseFloat(item.quantidade || 0);
			}
		});

		let custoMedioLitroVenda = valorTotalValorVenda > 0 ? valorTotalCustoVenda / valorTotalValorVenda : 0;

        return (
			<>
				<Content>

					{this.modalCustoFolha()}

					<Row>
						<Select
							options={this.props.listaAno}
							label='Ano'
							cols='12 4 2 2'
							placeholder='Selecione'
							value={this.state.ano}
							onChange={value => {
								this.atualizarCookie({ ...this.state, ano: value });
							}} />

						<Select
							options={this.props.listaMeses}
							label='Meses'
							cols='12 8 10 10'
							placeholder='Selecione'
							multiple
							value={this.props.listaMeses.filter(item => this.state.meses.includes(item.id)).map(item => ({ value: item.id, label: item.valor }))}
							onChange={values => {
								this.atualizarCookie({ ...this.state, meses: (values || []).map(item => item.value) });
							}} />
					</Row>
					
					<Row>
						
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								padding: 4,
								flexWrap: 'wrap',
								justifyContent: 'center'
							}}>
							<div class='form-group clearfix' style={{ padding: 4, width: 200 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='custoFrota' 
										checked={this.state.custoFrota}
										onChange={() => this.atualizarCookie({ ...this.state, custoFrota: !this.state.custoFrota })} />
									<label for='custoFrota'>
										Custo com Frota
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 250 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='custoAbastecida' 
										checked={this.state.custoAbastecida}
										onChange={() => this.atualizarCookie({ ...this.state, custoAbastecida: !this.state.custoAbastecida })} />
									<label for='custoAbastecida'>
										Custo com Abastecidas
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 200 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='custoFolha' 
										checked={this.state.custoFolha}
										onChange={() => this.atualizarCookie({ ...this.state, custoFolha: !this.state.custoFolha })} />
									<label for='custoFolha'>
										Custo com Folha
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 200 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='custoOutros' 
										checked={this.state.custoOutros}
										onChange={() => this.atualizarCookie({ ...this.state, custoOutros: !this.state.custoOutros })} />
									<label for='custoOutros'>
										Outros Custos
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 220 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='totalKmRodado' 
										checked={this.state.totalKmRodado}
										onChange={() => this.atualizarCookie({ ...this.state, totalKmRodado: !this.state.totalKmRodado })} />
									<label for='totalKmRodado'>
										Total de km Rodado
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 230 }}>						
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='mediaKmRodado' 
										checked={this.state.mediaKmRodado}
										onChange={() => this.atualizarCookie({ ...this.state, mediaKmRodado: !this.state.mediaKmRodado })} />
									<label for='mediaKmRodado'>
										Média de km Rodado
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 280 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='custoFrotaKmRodado' 
										checked={this.state.custoFrotaKmRodado}
										onChange={() => this.atualizarCookie({ ...this.state, custoFrotaKmRodado: !this.state.custoFrotaKmRodado })} />
									<label for='custoFrotaKmRodado'>
										Custo da Frota por km Rodado
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 330 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='custoAbastecidaKmRodado' 
										checked={this.state.custoAbastecidaKmRodado}
										onChange={() => this.atualizarCookie({ ...this.state, custoAbastecidaKmRodado: !this.state.custoAbastecidaKmRodado })} />
									<label for='custoAbastecidaKmRodado'>
										Custo de Abastecidas por km Rodado
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 250 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='vendasValor' 
										checked={this.state.vendasValor}
										onChange={() => this.atualizarCookie({ ...this.state, vendasValor: !this.state.vendasValor })} />
									<label for='vendasValor'>
										Vendas Realizadas (R$)
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 250 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='vendasQuantidade' 
										checked={this.state.vendasQuantidade}
										onChange={() => this.atualizarCookie({ ...this.state, vendasQuantidade: !this.state.vendasQuantidade })} />
									<label for='vendasQuantidade'>
										Vendas Realizadas (Litros)
									</label>
								</div>
							</div>
							<div class='form-group clearfix' style={{ padding: 4, width: 250 }}>
								<div class='icheck-primary d-inline'>
									<input type='checkbox' id='custoLitroVenda' 
										checked={this.state.custoLitroVenda}
										onChange={() => this.atualizarCookie({ ...this.state, custoLitroVenda: !this.state.custoLitroVenda })} />
									<label for='custoLitroVenda'>
										Custo por Litro Vendido (R$)
									</label>
								</div>
							</div>
						</div>
					</Row>
					<Row>

						{this.state.custoFrota &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Custo com Frota</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										R$ {FormatUtils.formatarValorTelaDecimal(valorTotalFrota, 2)}
									</div>
									
									<div style={{
										height: 200,
										overflowY: 'auto',
										marginBottom: 16
									}}>
										{listaCustoFrota.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {

											let percentual = maiorValorFrota ? (parseFloat(item.valor) * 100) / maiorValorFrota : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>R$ {FormatUtils.formatarValorTelaDecimal(item.valor, 2)}</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 2
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}
									</div>

								</contentCardBody>
							</ContentCard>
						</Grid>}

						{this.state.custoAbastecida &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Custo com Abastecidas</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										R$ {FormatUtils.formatarValorTelaDecimal(valorTotalAbastecida, 2)}
									</div>
									
									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>
										{listaCustoAbastecida.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {

											let percentual = maiorValorAbastecida ? (parseFloat(item.valor) * 100) / maiorValorAbastecida : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>R$ {FormatUtils.formatarValorTelaDecimal(item.valor, 2)}</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}
									</div>

								</contentCardBody>
							</ContentCard>
						</Grid>}

						{this.state.custoFolha &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Custo com Folha</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										R$ {FormatUtils.formatarValorTelaDecimal(valorTotalFolha, 2)}
									</div>

									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>
										{listaCustoFolha.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {

											let percentual = maiorValorFolha ? (parseFloat(item.valor) * 100) / maiorValorFolha : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														{/*<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]} 
															<a href='' 
																class='text-muted'
																style={{ marginLeft: 8 }}
																onClick={e => {
																	e.preventDefault();

																	this.setModalCustoFolha({
																		...item,
																		valor: FormatUtils.formatarValorTela(item.valor)
																	});
																}} >
																<i class='fas fa-pencil-alt'></i>
															</a>
														</label>*/}
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>R$ {FormatUtils.formatarValorTelaDecimal(item.valor, 2)}</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}
									</div>
									{/*<Row>
										{this.state.ano > 0 &&
										<Grid cols='12 12 8 6' 
											style={{
												paddingTop: 8,
												paddingLeft: 22,
												paddingBottom: 16
											}}>
											<Button
												text='Adicionar'
												type={'success'}
												icon={'fa fa-plus'}
												event={() => {

													let ultimo = listaCustoFolha.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano)[listaCustoFolha.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).length - 1];
													
													let competencia = null;
													if (!ultimo) {
														competencia = `${this.state.ano}01`;
													} else if (ultimo.competencia == `${this.state.ano}12`) {
														setError('Todos os meses foram adicionados');
														return;
													} else {
														competencia = parseInt(ultimo.competencia) + 1;
													}

													this.setModalCustoFolha({
														competencia: competencia
													});
												}} />
										</Grid>}
									</Row>*/}
								</contentCardBody>
							</ContentCard>
						</Grid>}

						{this.state.custoOutros &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Outros Custos</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										R$ {FormatUtils.formatarValorTelaDecimal(valorTotalOutros, 2)}
									</div>

									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>
										{listaCustoOutrasDespesas.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {

											let percentual = maiorValorOutros ? (parseFloat(item.valor) * 100) / maiorValorOutros : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>R$ {FormatUtils.formatarValorTelaDecimal(item.valor, 2)}</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}
									</div>
								</contentCardBody>
							</ContentCard>
						</Grid>}
						
						{this.state.totalKmRodado &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Total de km Rodado</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										{FormatUtils.formatarValorTelaDecimal(valorTotalKmRodado, 0)} km
									</div>

									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>

										{listaKmRodado.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {

											let percentual = maiorKmRodado ? (parseFloat(item.km_rodado_total) * 100) / maiorKmRodado : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>{FormatUtils.formatarValorTelaDecimal(item.km_rodado_total, 0)} km</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}

									</div>

								</contentCardBody>
							</ContentCard>
						</Grid>}

						{this.state.mediaKmRodado &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Média de km Rodado</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										{FormatUtils.formatarValorTelaDecimal(valorTotalKmRodadoMedia, 0)} km
									</div>

									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>

										{listaKmRodado.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {

											let percentual = maiorKmRodadoMedia ? (parseFloat(item.km_rodado_media) * 100) / maiorKmRodadoMedia : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>{FormatUtils.formatarValorTelaDecimal(item.km_rodado_media, 0)} km</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}

									</div>

								</contentCardBody>
							</ContentCard>
						</Grid>}

						{this.state.custoFrotaKmRodado &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Custo da Frota por km Rodado</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										R$ {FormatUtils.formatarValorTelaDecimal(valorTotalCustoFrotaKmRodado, 2)}
									</div>

									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>
										{listaKmRodado.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {
											
											const custoFrota = listaCustoFrota.filter(itemCusto => itemCusto.competencia == item.competencia)[0];
											
											const custoMes = parseFloat(custoFrota ? custoFrota.valor : 0);

											const custoKmRodado = parseFloat(item.km_rodado_total) > 0 ? custoMes / parseFloat(item.km_rodado_total) : 0;
											
											let percentual = maiorCustoFrotaKmRodado ? (custoKmRodado * 100) / maiorCustoFrotaKmRodado : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>R$ {FormatUtils.formatarValorTelaDecimal(custoKmRodado, 2)}</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}

									</div>

								</contentCardBody>
							</ContentCard>
						</Grid>}

						{this.state.custoAbastecidaKmRodado &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Custo de Abastecidas por km Rodado</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										R$ {FormatUtils.formatarValorTelaDecimal(valorTotalCustoAbastecidaKmRodado, 2)}
									</div>

									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>

										{listaKmRodado.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {
											
											const custoAbastecida = listaCustoAbastecida.filter(itemCusto => itemCusto.competencia == item.competencia)[0];
											
											const custoMes = parseFloat(custoAbastecida ? custoAbastecida.valor : 0);

											const custoKmRodado = parseFloat(item.km_rodado_total) > 0 ? custoMes / parseFloat(item.km_rodado_total) : 0;
											
											let percentual = maiorCustoAbastecidaKmRodado ? (custoKmRodado * 100) / maiorCustoAbastecidaKmRodado : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>R$ {FormatUtils.formatarValorTelaDecimal(custoKmRodado, 2)}</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}
										
									</div>

								</contentCardBody>
							</ContentCard>
						</Grid>}

						{this.state.vendasValor &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Vendas Realizadas (R$)</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										R$ {FormatUtils.formatarValorTelaDecimal(valorTotalVenda, 2)}
									</div>
									
									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>
										{listaVenda.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {

											let percentual = maiorValorVenda ? (parseFloat(item.valor) * 100) / maiorValorVenda : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>R$ {FormatUtils.formatarValorTelaDecimal(item.valor, 2)}</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}
									</div>

								</contentCardBody>
							</ContentCard>
						</Grid>}

						{this.state.vendasQuantidade &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Vendas Realizadas (Litros)</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										{FormatUtils.formatarValorTelaDecimal(quantidadeTotalVenda, 2)} L
									</div>
									
									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>
										{listaVenda.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {

											let percentual = maiorQuantidadeVenda ? (parseFloat(item.quantidade) * 100) / maiorQuantidadeVenda : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 2)} L</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}
									</div>

								</contentCardBody>
							</ContentCard>
						</Grid>}

						{this.state.custoLitroVenda &&
						<Grid cols='4 4 4 4'>
							<ContentCard>
								<ContentCardHeader>
									<label style={{ width: '100%', textAlign: 'center' }}>Custo por Litro Vendido (R$)</label>
								</ContentCardHeader>
								<contentCardBody>
									<div 
										style={{
											textAlign: 'center',
											fontSize: 32,
											color: '#8c8181',
											padding: 2
										}}>
										R$ {FormatUtils.formatarValorTelaDecimal(custoMedioLitroVenda, 2)}
									</div>
									
									<div style={{
											height: 200,
											overflowY: 'auto',
											marginBottom: 16
										}}>
										{listaCustoLitroVenda.filter(item => parseInt(parseInt(item.competencia) / 100) == this.state.ano).map(item => {

											let percentual = maiorCustoLitroVenda ? (parseFloat(item.valor) * 100) / maiorCustoLitroVenda : 0;

											return (
												<div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<label>{meses[parseInt(String(item.competencia).substring(4, 6)) - 1]}</label>
														<label>R$ {FormatUtils.formatarValorTelaDecimal(item.valor, 2)}</label>
													</div>
													<div
														style={{
															padding: 2,
															backgroundColor: '#b8b8b8',
															borderRadius: 4
														}}>
														<div
															style={{
																height: 8,
																width: `${percentual}%`,
																backgroundColor: '#6765ad',
																borderRadius: 2
															}}>

														</div>
													</div>
												</div>
											);
										})}
									</div>

								</contentCardBody>
							</ContentCard>
						</Grid>}
					</Row>
				</Content>
			</>
        )
    }

	setModalCustoFolha(registro) {
		this.setState({
			...this.state,
			modalCustoFolha: registro
		});
	}

	modalCustoFolha() {

		if (!this.state.modalCustoFolha) {
			return null;
		}

		let ano = parseInt(parseInt(this.state.modalCustoFolha.competencia) / 100);
		let mes = meses[parseInt(String(this.state.modalCustoFolha.competencia).substring(4, 6)) - 1];

        return (
			<div class='modal fade show' id='modal-md' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-md'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Custo com Folha - {mes} de {ano}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setModalCustoFolha(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ }}>
							<Row alignCenter>
								<LabelAndInputNumber 
									cols='12 12 12 12'
									label='Valor'
									placeholder='0,00'
									casas={0}
									value={this.state.modalCustoFolha.valor}
									onChange={data => {
										this.setModalCustoFolha({
											...this.state.modalCustoFolha,
											valor: data.target.value
										});
									}} />
							</Row>

							<Row alignCenter>
								<Grid cols='12 12 12 12' style={{ padding: 16 }}>
									<Button
										text={!this.state.modalCustoFolha.id ? 'Adicionar' : 'Alterar'}
										type={'success'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.salvarCustoFolha({
												...this.state.modalCustoFolha
											});
											this.setModalCustoFolha(null);
										}} />
								</Grid>

								{this.state.modalCustoFolha.id &&
								<Grid cols='12 12 12 12' style={{ padding: 16 }}>
									<Button
										text={'Excluir'}
										type={'danger'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.excluirCustoFolha({
												...this.state.modalCustoFolha
											});
											this.setModalCustoFolha(null);
										}} />
								</Grid>}
							</Row>
						</div>
						<div class='modal-footer justify-content-between' style={{ height: 2, padding: 0, margin: 0 }}>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboardCusto.aguardando,
	listaAno: state.dashboardCusto.listaAno,
	listaMeses: state.dashboardCusto.listaMeses,
	listaCustoFolha: state.dashboardCusto.listaCustoFolha,
	listaCustoFrota: state.dashboardCusto.listaCustoFrota,
	listaCustoOutrasDespesas: state.dashboardCusto.listaCustoOutrasDespesas,
	listaCustoAbastecida: state.dashboardCusto.listaCustoAbastecida,
	listaKmRodado: state.dashboardCusto.listaKmRodado,
	listaVenda: state.dashboardCusto.listaVenda
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaCustoFolha, getListaCustoFrota, getListaCustoOutrasDespesas,
	getListaCustoAbastecida, getListaKmRodado, 
	salvarCustoFolha, excluirCustoFolha, getListaVenda
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardCusto)
