import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'META_VENDA_TRIMESTRE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'META_VENDA_TRIMESTRE_SET_AGUARDANDO',
        payload: aguardando
    };
}


export function initForm(registro = {}) {
    return [
        initialize('metaVendaTrimestreForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/metaVendaTrimestre`);
    return {
        type: 'META_VENDA_TRIMESTRE_LISTADO',
        payload: request
    };
}

export function getListaApuracao() {
    const request = axios.get(`${consts.API_URL}/metaVendaTrimestre/apuracao`);
    return {
        type: 'META_VENDA_TRIMESTRE_APURACAO_LISTADO',
        payload: request
    };
}

export function getListaApuracaoGeral() {
    const request = axios.get(`${consts.API_URL}/metaVendaTrimestre/listarApuracaoGeral`);
    return {
        type: 'META_VENDA_TRIMESTRE_APURACAO_GERAL_LISTADO',
        payload: request
    };
}

export function getListaApuracaoGeralDiario() {
    const request = axios.get(`${consts.API_URL}/metaVendaTrimestre/listarApuracaoGeralDiario`);
    return {
        type: 'META_VENDA_TRIMESTRE_APURACAO_GERAL_DIARIO_LISTADO',
        payload: request
    };
}

export function getListaApuracaoGeralMensal() {
    const request = axios.get(`${consts.API_URL}/metaVendaTrimestre/listarApuracaoGeralMensal`);
    return {
        type: 'META_VENDA_TRIMESTRE_APURACAO_GERAL_MENSAL_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/metaVendaTrimestre`, {
                ...registro,
				volume_1: FormatUtils.formatarValorBanco(registro.volume_1 || 0, 2),
				volume_2: FormatUtils.formatarValorBanco(registro.volume_2 || 0, 2),
				volume_3: FormatUtils.formatarValorBanco(registro.volume_3 || 0, 2),
				volume_4: FormatUtils.formatarValorBanco(registro.volume_4 || 0, 2),
				volume_5: FormatUtils.formatarValorBanco(registro.volume_5 || 0, 2),
				margem_1: FormatUtils.formatarValorBanco(registro.margem_1 || 0, 2),
				margem_2: FormatUtils.formatarValorBanco(registro.margem_2 || 0, 2),
				margem_3: FormatUtils.formatarValorBanco(registro.margem_3 || 0, 2),
				margem_4: FormatUtils.formatarValorBanco(registro.margem_4 || 0, 2),
				margem_5: FormatUtils.formatarValorBanco(registro.margem_5 || 0, 2),
				prazo_1: FormatUtils.formatarValorBanco(registro.prazo_1 || 0, 2),
				prazo_2: FormatUtils.formatarValorBanco(registro.prazo_2 || 0, 2),
				prazo_3: FormatUtils.formatarValorBanco(registro.prazo_3 || 0, 2),
				prazo_4: FormatUtils.formatarValorBanco(registro.prazo_4 || 0, 2),
				prazo_5: FormatUtils.formatarValorBanco(registro.prazo_5  || 0, 2),
				inadimplencia_1_maxima: FormatUtils.formatarValorBanco(registro.inadimplencia_1_maxima || 0, 2),
				inadimplencia_1_perda: FormatUtils.formatarValorBanco(registro.inadimplencia_1_perda || 0, 2),
				inadimplencia_2_maxima: FormatUtils.formatarValorBanco(registro.inadimplencia_2_maxima || 0, 2),
				inadimplencia_2_perda: FormatUtils.formatarValorBanco(registro.inadimplencia_2_perda || 0, 2),
				cliente_retomada_1: FormatUtils.formatarValorBanco(registro.cliente_retomada_1 || 0, 0),
                cliente_retomada_2: FormatUtils.formatarValorBanco(registro.cliente_retomada_2 || 0, 0),
                cliente_retomada_3: FormatUtils.formatarValorBanco(registro.cliente_retomada_3 || 0, 0),
                cliente_retomada_4: FormatUtils.formatarValorBanco(registro.cliente_retomada_4 || 0, 0),
                cliente_retomada_5: FormatUtils.formatarValorBanco(registro.cliente_retomada_5 || 0, 0),
                cliente_novo_1: FormatUtils.formatarValorBanco(registro.cliente_novo_1 || 0, 0),
                cliente_novo_2: FormatUtils.formatarValorBanco(registro.cliente_novo_2 || 0, 0),
                cliente_novo_3: FormatUtils.formatarValorBanco(registro.cliente_novo_3 || 0, 0),
                cliente_novo_4: FormatUtils.formatarValorBanco(registro.cliente_novo_4 || 0, 0),
                cliente_novo_5: FormatUtils.formatarValorBanco(registro.cliente_novo_5 || 0, 0),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/metaVendaTrimestre`, {
                ...registro,
				volume_1: FormatUtils.formatarValorBanco(registro.volume_1 || 0, 2),
				volume_2: FormatUtils.formatarValorBanco(registro.volume_2 || 0, 2),
				volume_3: FormatUtils.formatarValorBanco(registro.volume_3 || 0, 2),
				volume_4: FormatUtils.formatarValorBanco(registro.volume_4 || 0, 2),
				volume_5: FormatUtils.formatarValorBanco(registro.volume_5 || 0, 2),
				margem_1: FormatUtils.formatarValorBanco(registro.margem_1 || 0, 2),
				margem_2: FormatUtils.formatarValorBanco(registro.margem_2 || 0, 2),
				margem_3: FormatUtils.formatarValorBanco(registro.margem_3 || 0, 2),
				margem_4: FormatUtils.formatarValorBanco(registro.margem_4 || 0, 2),
				margem_5: FormatUtils.formatarValorBanco(registro.margem_5 || 0, 2),
				prazo_1: FormatUtils.formatarValorBanco(registro.prazo_1 || 0, 2),
				prazo_2: FormatUtils.formatarValorBanco(registro.prazo_2 || 0, 2),
				prazo_3: FormatUtils.formatarValorBanco(registro.prazo_3 || 0, 2),
				prazo_4: FormatUtils.formatarValorBanco(registro.prazo_4 || 0, 2),
				prazo_5 : FormatUtils.formatarValorBanco(registro.prazo_5  || 0, 2),
				inadimplencia_1_maxima: FormatUtils.formatarValorBanco(registro.inadimplencia_1_maxima || 0, 2),
				inadimplencia_1_perda: FormatUtils.formatarValorBanco(registro.inadimplencia_1_perda || 0, 2),
				inadimplencia_2_maxima: FormatUtils.formatarValorBanco(registro.inadimplencia_2_maxima || 0, 2),
				inadimplencia_2_perda: FormatUtils.formatarValorBanco(registro.inadimplencia_2_perda || 0, 2),
				cliente_retomada_1: FormatUtils.formatarValorBanco(registro.cliente_retomada_1 || 0, 0),
                cliente_retomada_2: FormatUtils.formatarValorBanco(registro.cliente_retomada_2 || 0, 0),
                cliente_retomada_3: FormatUtils.formatarValorBanco(registro.cliente_retomada_3 || 0, 0),
                cliente_retomada_4: FormatUtils.formatarValorBanco(registro.cliente_retomada_4 || 0, 0),
                cliente_retomada_5: FormatUtils.formatarValorBanco(registro.cliente_retomada_5 || 0, 0),
                cliente_novo_1: FormatUtils.formatarValorBanco(registro.cliente_novo_1 || 0, 0),
                cliente_novo_2: FormatUtils.formatarValorBanco(registro.cliente_novo_2 || 0, 0),
                cliente_novo_3: FormatUtils.formatarValorBanco(registro.cliente_novo_3 || 0, 0),
                cliente_novo_4: FormatUtils.formatarValorBanco(registro.cliente_novo_4 || 0, 0),
                cliente_novo_5: FormatUtils.formatarValorBanco(registro.cliente_novo_5 || 0, 0),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/metaVendaTrimestre?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'META_VENDA_TRIMESTRE_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function importarVendas(tabela) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		let lista = [];

		for (var i = 0; i < tabela.length; i++) {
			let item = tabela[i];

			let dataHora = new Date(String(item[4]));
			dataHora.setHours(dataHora.getHours() + 3);
			let data = dataHora ? `${dataHora.getFullYear()}/${String(dataHora.getMonth() + 1).padStart(2, '0')}/${String(dataHora.getDate()).padStart(2, '0')}` : null;

			let venda = {
				serie_documento: String(item[2] || '').trim(),
				numero_documento: String(item[3] || '').trim(),
				codigoOperacao: String(item[6] || '').trim(),
				data_emissao: data,
				nome_cliente: String(item[10] || '').trim(), 
				nome_vendedor: String(item[14] || '').trim(),
				produto: String(item[19] || '').trim(),
				quantidade: parseFloat(String(item[20] || '').trim().replace(',', '.') || 0),
				valor: parseFloat(String(item[21] || '').trim().replace(',', '.') || 0),
				custo: parseFloat(String(item[30] || '').trim().replace(',', '.') || 0),
				prazo: parseFloat(String(item[38] || '').trim().replace(',', '.') || 0),
				id_petroshow: String(item[9] || '').trim(),
				cpf_cnpj: String(item[11] || '').trim(),
				inscricao_estadual: String(item[12] || '').trim(),
				endereco: String(item[13] || '').trim()
			};
			
			if ((venda.codigoOperacao.includes('VENDA DE COMBUSTIVEIS') || venda.codigoOperacao.includes('VENDA DE ARLA'))
				&& (venda.produto.includes('DIESEL') && (venda.produto.includes('S10') || (venda.produto.includes('S500'))))
				|| venda.produto.includes('ARLA 32 - GRANEL')) {
				lista.push(venda);
			}

		}

		await axios.post(`${consts.API_URL}/vendaImportacao`, {
			lista: lista
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaApuracao());
		}).catch(e => {
			setErroAPI(e);
		});

		dispatch(setAguardando(false));
	}
}

export function importarInadimplencia(tabela, registro) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		let listaClientes = [];

		await axios.get(`${consts.API_URL}/cliente`).then(result => {
			listaClientes = result.data;
		}).catch(ex => {
			setErroAPI('Erro ao listar clientes');
		});

		let listaInadimplentes = [];

		if (listaClientes.length) {
			
			let codCliente = null;
			
			for (var i = 0; i < tabela.length; i++) {
				let item = tabela[i];

				if (!codCliente || parseInt(String(item[0] || '').split('-')[0].trim())) {
					codCliente = parseInt(String(item[0] || '').split('-')[0].trim());
				}
				if (codCliente && item[2] && item[4]) {

					const vencimentoTime = new Date(item[2]).getTime() + 10800000;
					let vencimento = DateFormat.getTimestampParaData(vencimentoTime);
				
					const valor = parseFloat(String(item[4] || '').trim().replace(',', '.') || 0);
					
					//30 dias
					if (vencimentoTime && (new Date().getTime() - vencimentoTime) > 2592000000 && valor > 0) {
						
						const cliente = listaClientes.filter(cli => cli.id_petroshow == codCliente)[0];					

						if (cliente) {
							listaInadimplentes.push({
								id_cliente: cliente.id,
								codigo_vendedor: cliente.vendedores ? cliente.vendedores[0] || null : null,
								data_atual: DateFormat.getDataAtual(),
								data_vencimento: vencimento,
								valor: valor
							});
						}
					}
				}
			}
		}

		await axios.post(`${consts.API_URL}/inadimplenciaImportacao`, {
			lista: listaInadimplentes
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaApuracao());
		}).catch(e => {
			setErroAPI(e);
		});

		dispatch(setAguardando(false));

	}
}

export function excluirInadimplencia(registro) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		for (let i = 0; i < registro.ids.length; i++) {
			const id = registro.ids[i];
			console.log(id);
			await axios.delete(`${consts.API_URL}/inadimplenciaImportacao?id=${id}`).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
			}).catch(e => {
				setErroAPI(e);
			});
		}		

		dispatch(getListaApuracao());
		dispatch(setAguardando(false));

	}
}

export function excluirClienteNovo(registro) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		for (let i = 0; i < registro.ids.length; i++) {
			const id = registro.ids[i];
			
			await axios.delete(`${consts.API_URL}/vendaImportacao/clienteNovo?id=${id}`).then(resp => {
				
			}).catch(e => {
				setErroAPI(e);
			});
		}		
		setSuccess('Operação Realizada com sucesso.');
		dispatch(getListaApuracao());
		dispatch(setAguardando(false));

	}
}

export function excluirClienteRetomada(registro) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		for (let i = 0; i < registro.ids.length; i++) {
			const id = registro.ids[i];
			
			await axios.delete(`${consts.API_URL}/vendaImportacao/clienteRetomada?id=${id}`).then(resp => {
				
			}).catch(e => {
				setErroAPI(e);
			});
		}		
		setSuccess('Operação Realizada com sucesso.');
		dispatch(getListaApuracao());
		dispatch(setAguardando(false));

	}
}

export function imprimir(registro) {
	return (dispatch, getState) => {
		
		axios.post(`${consts.API_URL}/metaVendaTrimestre/imprimir`, {
			...registro,
			datahora_emissao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
		})
		.then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});
	}
}