import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	importarVendas, importarInadimplencia,
	getListaApuracao, imprimir
} from './metaVendaTrimestreActions';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import imagemLogo from '../assets/images/logo.png';

class MetaVendaTrimestreApuracao extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		ano: 2025,
		trimestre: 2
	}

    componentDidMount() {

		const fileSelectorVendas = document.createElement('input');
		fileSelectorVendas.setAttribute('type', 'file');
		fileSelectorVendas.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 7 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarVendas(tabela);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorVendas = fileSelectorVendas;

		const fileSelectorInadimplencia = document.createElement('input');
		fileSelectorInadimplencia.setAttribute('type', 'file');
		fileSelectorInadimplencia.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarInadimplencia(tabela);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorInadimplencia = fileSelectorInadimplencia;
	}

	componentWillMount() {
		this.props.getListaApuracao();

		let idInterval = setInterval(() => {
			this.props.getListaApuracao();
		}, 30000);

		this.setState({
			...this.state,
			idInteral: idInterval
		});
	}

	componentWillUnmount() {
		
		clearInterval(this.state.idInteral);
		
	}


    render() {
        return (
            <div>
				
				<Aguardando aguardando={this.props.aguardando} />

                <Content>

					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 24 }}>
						<img src={imagemLogo} style={{ width: 250, height: 79 }}/>
						<div style={{ display: 'flex', height: 79, flexDirection: 'column', marginLeft:24, justifyContent: 'center', paddingTop: 25 }}>
							<h4 style={{ fontSize: 18, fontWeight: 'bold', color: '#194682' }}>META DE VENDAS - TRR VALE DA SERRA</h4>
							<h5 style={{ fontSize: 14, fontWeight: 'bold', color: '#194682' }}>NO VALE DO ARAGUAIA, COMBUSTÍVEL É VALE DA SERRA!</h5>
						</div>
					</div>

					{(this.props.sessao && this.props.sessao.id != 51 /*TV*/) &&
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 3 2'>
								<Button
									text='Importar Vendas'
									type={'primary'}
									icon={'fa fa-chevron-left'}
									event={() => {
										if (window.confirm('Remova a linha do cabeçalho do arquivo Excel (primeira linha) para depois importar')) {
											this.fileSelectorVendas.click()
										}
									}} />
							</Grid>

							<Grid cols='6 6 3 2'>
								<Button
									text='Importar Inadimplência'
									type={'primary'}
									icon={'fa fa-chevron-left'}
									event={() => {
										if (window.confirm('Remova a linha do cabeçalho do arquivo Excel para depois importar')) {
											this.fileSelectorInadimplencia.click()
										}
									}} />
							</Grid>
						</Row>
					</ContentCardHeader>}
					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
		
		return (
			<ContentCard>
				<ContentCardHeader>
					
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						<Select
							options={[{ id: 2024, valor: 2024 }, { id: 2025, valor: 2025 }, { id: 2026, valor: 2026 }]}
							label='Ano'
							cols='12 4 3 2'
							placeholder='Selecione o ano'
							value={this.state.ano}
							onChange={value => {
								this.setState({
									...this.state,
									ano: value
								});
							}} />
								
						<Select
							options={[{ id: 1, valor: '1º trimestre' }, { id: 2, valor: '2º trimestre' }, { id: 3, valor: '3º trimestre' }, { id: 4, valor: '4º trimestre' } ]}
							label='Trimestre'
							cols='12 4 3 2'
							placeholder='Selecione o trimestre'
							value={this.state.trimestre}
							onChange={value => {
								this.setState({
									...this.state,
									trimestre: value
								});
							}} />
					</Row>
				
					{this.props.listaApuracao.filter(item => {
						if (this.state.ano && this.state.trimestre) {
							return item.ano == this.state.ano && item.trimestre == this.state.trimestre;
						}
						return false;
					}).map(item => {

						let inadimplenciaGanho = 100;
						if (parseFloat(item.inadimplencia_2_maxima || 0) > 0 
							&& parseFloat(item.inadimplencia_realizado || 0) > parseFloat(item.inadimplencia_2_maxima || 0)) {
							inadimplenciaGanho = 100 - parseFloat(item.inadimplencia_2_perda);
						} else if (parseFloat(item.inadimplencia_1_maxima || 0) > 0 
							&& parseFloat(item.inadimplencia_realizado || 0) > parseFloat(item.inadimplencia_1_maxima || 0)) {
							inadimplenciaGanho = 100 - parseFloat(item.inadimplencia_1_perda);
						}
						
						return (
							<Table responsive key={item.id}>
								<THead>
									<Tr color={item.geral ? '#ffffff' : '#194682'}
										backgroundColor={item.geral ? '#194682' : '#dfe2f5'}>
										<Th width={500} fontSize={18}>
											{item.nome}
											{(this.props.usuarioCarregado && this.props.usuarioCarregado.id_perfil == 2) && /*ADMINISTRADOR*/
											<button 
												type='button'
												class='btn btn-default btn-flat'
												style={{ marginLeft: 16 }}
												onClick={() => {
													this.props.imprimir({
														...item,
														nome: item.nome,
														ano_trimestre: `${item.trimestre}° trimestre de ${item.ano}`,
														itens: [{
															nome: 'Volume',
															meta: FormatUtils.formatarValorTelaDecimal(item.volume_5, 0),
															realizado: FormatUtils.formatarValorTelaDecimal(item.volume_realizado, 0),
															faixa: item.faixa_volume
														}, {
															nome: 'Margem',
															meta: FormatUtils.formatarValorTelaDecimal(item.margem_5, 2),
															realizado: FormatUtils.formatarValorTelaDecimal(item.margem_realizado, 2),
															faixa: item.faixa_margem
														}, {
															nome: 'Prazo',
															meta: FormatUtils.formatarValorTelaDecimal(item.prazo_5, 2),
															realizado: FormatUtils.formatarValorTelaDecimal(item.prazo_realizado, 2),
															faixa: item.faixa_prazo
														}, {
															nome: 'Inadimplência',
															meta: FormatUtils.formatarValorTelaDecimal(item.inadimplencia_1_maxima, 2),
															realizado: FormatUtils.formatarValorTelaDecimal(item.inadimplencia_realizado, 2),
															faixa: `Ganha ${FormatUtils.formatarValorTelaDecimal(inadimplenciaGanho, 0)}%`
														}, {
															nome: item.cliente_retomada_1 > 0 ? 'Clientes Novos e Retomada' : null,
															meta: FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_5, 0),
															realizado: FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_realizado, 0),
															faixa: item.faixa_cliente_retomada
														}].filter(meta => meta.nome)
													});															
												}}>
												<i class='fas fa-file-pdf' style={{ fontSize: 18 }}></i>
											</button>}
										</Th>
										<Th width={100} alignCenter fontSize={18}>Meta</Th>
										<Th width={100} alignCenter fontSize={18}>Realizado</Th>
										<Th width={100} alignCenter fontSize={18}>Projetado</Th>
										<Th width={100} alignCenter fontSize={18}>Faixa</Th>
									</Tr>
								</THead>
								<TBody>								
									<Tr>
										<Td width={500}>Volume</Td>
										<Td width={100} alignCenter fontSize={24}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.volume_1, 0)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.volume_2, 0)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.volume_3, 0)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.volume_4, 0)} \n`
												+ `Faixa 5: ${FormatUtils.formatarValorTelaDecimal(item.volume_5, 0)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.volume_5, 0)}</Td>
										<Td width={100} alignCenter fontSize={24} >{FormatUtils.formatarValorTelaDecimal(item.volume_realizado, 0)}</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.volume_projetado, 0)}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_volume}</Td>
									</Tr>
									<Tr>
										<Td width={500}>Margem</Td>
										<Td width={100} alignCenter fontSize={24}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.margem_1, 2)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.margem_2, 2)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.margem_3, 2)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.margem_4, 2)} \n`
												+ `Faixa 5: ${FormatUtils.formatarValorTelaDecimal(item.margem_5, 2)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.margem_5, 2)}</Td>
										<Td width={100} alignCenter fontSize={24} >{FormatUtils.formatarValorTelaDecimal(item.margem_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.margem_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_margem}</Td>
									</Tr>
									<Tr>
										<Td width={500}>Prazo</Td>
										<Td width={100} alignCenter fontSize={24}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.prazo_1, 2)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.prazo_2, 2)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.prazo_3, 2)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.prazo_4, 2)} \n`
												+ `Faixa 5: ${FormatUtils.formatarValorTelaDecimal(item.prazo_5, 2)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.prazo_5, 2)}</Td>
										<Td width={100} alignCenter fontSize={24} >{FormatUtils.formatarValorTelaDecimal(item.prazo_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.prazo_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_prazo}</Td>
									</Tr>
									<Tr>
										<Td width={500}>Inadimplência</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.inadimplencia_1_maxima, 2)}</Td>
										<Td width={100} alignCenter fontSize={24} 
											title={(item.inadimplencia_itens || []).filter(inadimplencia => inadimplencia).map(inadimplencia => (
												`${inadimplencia.id_cliente} - ${inadimplencia.nome_cliente}: R$ ${FormatUtils.formatarValorTelaDecimal(inadimplencia.valor, 2)}`
											)).join('\n')}>{FormatUtils.formatarValorTelaDecimal(item.inadimplencia_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.inadimplencia_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={24}>Ganha {FormatUtils.formatarValorTelaDecimal(inadimplenciaGanho, 0)}%</Td>
									</Tr>
									
									{item.cliente_retomada_1 > 0 &&
									<Tr>
										<Td width={500}>Clientes Novos e Retomada</Td>
										<Td width={100} alignCenter fontSize={24}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_1, 0)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_2, 0)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_3, 0)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_4, 0)} \n`
												+ `Faixa 5: ${FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_5, 0)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_5, 0)}</Td>
										<Td width={100} alignCenter fontSize={24}
											title={(item.clientes_retomados || []).map(cliente => 
												+ `${cliente.id_petroshow || ''}`
												+ ` - ${(cliente.cpf_cnpj || '').length == 11 ? FormatUtils.formatarCPF(cliente.cpf_cnpj) : FormatUtils.formatarCNPJ(cliente.cpf_cnpj)}`
												+ ` - ${cliente.nome_cliente || 'Sem Nome'}`
												+ ` - ${cliente.endereco || 'Sem Endereço'}`
											).join('\n\n')}>
											{FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_realizado, 0)}</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.cliente_retomada_projetado, 0)}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_cliente_retomada}</Td>
									</Tr>}

									{/*item.cliente_novo_1 > 0 &&
									<Tr>
										<Td width={500}>Cliente - Novo</Td>
										<Td width={100} alignCenter fontSize={24}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.cliente_novo_1, 0)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.cliente_novo_2, 0)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.cliente_novo_3, 0)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.cliente_novo_4, 0)} \n`
												+ `Faixa 5: ${FormatUtils.formatarValorTelaDecimal(item.cliente_novo_5, 0)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.cliente_novo_5, 0)}</Td>
										<Td width={100} alignCenter fontSize={24} 
											title={(item.clientes_novos || []).map(cliente => 
												+ `${cliente.id_petroshow || ''}`
												+ ` - ${(cliente.cpf_cnpj || '').length == 11 ? FormatUtils.formatarCPF(cliente.cpf_cnpj) : FormatUtils.formatarCNPJ(cliente.cpf_cnpj)}`
												+ ` - ${cliente.nome_cliente || 'Sem Nome'}`
												+ ` - ${cliente.endereco || 'Sem Endereço'}`
											).join('\n\n')}>
											{FormatUtils.formatarValorTelaDecimal(item.cliente_novo_realizado, 0)}</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.cliente_novo_projetado, 0)}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_cliente_novo}</Td>
									</Tr>*/}

									<Tr>
										<Td width={500} bold fontSize={24}></Td>
										<Td width={100} alignCenter bold fontSize={24}></Td>
										<Td width={100} alignCenter bold fontSize={24}></Td>
										<Td width={100} alignCenter bold fontSize={24}></Td>
										<Td width={100} alignCenter bold fontSize={24}></Td>
									</Tr>
								</TBody>
							</Table>
						);
					})}
				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.metaVendaTrimestre.modoTela,
	aguardando: state.metaVendaTrimestre.aguardando,
	filtro: state.metaVendaTrimestre.filtro,
	listaApuracao: state.metaVendaTrimestre.listaApuracao.filter(item => {
		return state.auth.usuario.id == 1 || state.auth.usuario.id == 4 || state.auth.usuario.id == 13 //Janiel, Pedro e Fábio
			|| (item.lista_vendedor_nomes || []).includes((state.auth.usuario.nome || '').toUpperCase());
	})
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	importarVendas, importarInadimplencia,
	getListaApuracao, imprimir
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVendaTrimestreApuracao);
