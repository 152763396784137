import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize, registerField } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('metaManutencaoConfiguracaoForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/metaManutencaoConfiguracao`);
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_LISTADO',
        payload: request
    };
}

export function getListaMetaApuracao() {
    const request = axios.get(`${consts.API_URL}/metaManutencaoConfiguracao/apuracao`);
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_APURACAO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/metaManutencaoConfiguracao`, {
                ...registro,
				percentual_reducao_custo_frota_faixa_1: FormatUtils.formatarValorBanco(registro.percentual_reducao_custo_frota_faixa_1, 2),
				percentual_reducao_custo_frota_faixa_2: FormatUtils.formatarValorBanco(registro.percentual_reducao_custo_frota_faixa_2, 2),
				custo_frota_ano_anterior: FormatUtils.formatarValorBanco(registro.custo_frota_ano_anterior, 2),
				media_produtividade_km: FormatUtils.formatarValorBanco(registro.media_produtividade_km, 0),
				percentual_feedback_motorista: FormatUtils.formatarValorBanco(registro.percentual_feedback_motorista, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/metaManutencaoConfiguracao`, {
                ...registro,
				percentual_reducao_custo_frota_faixa_1: FormatUtils.formatarValorBanco(registro.percentual_reducao_custo_frota_faixa_1, 2),
				percentual_reducao_custo_frota_faixa_2: FormatUtils.formatarValorBanco(registro.percentual_reducao_custo_frota_faixa_2, 2),
				custo_frota_ano_anterior: FormatUtils.formatarValorBanco(registro.custo_frota_ano_anterior, 2),
				media_produtividade_km: FormatUtils.formatarValorBanco(registro.media_produtividade_km, 0),
				percentual_feedback_motorista: FormatUtils.formatarValorBanco(registro.percentual_feedback_motorista, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/metaManutencaoConfiguracao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_VEICULO_SELECT_LISTADO',
        payload: request
    };
}

export function importarCustos(tabela) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		let lista = [];

		let listaTipos = [];
		await axios.get(`${consts.API_URL}/custoTipo`).then(result => {
			listaTipos = result.data;
		});
		
		for (var i = 0; i < tabela.length; i++) {
			let item = tabela[i];

			let data = item[8];

			let analitica = (item[4] || '').split('-')[0] ? (item[4] || '').split('-')[0].replace('Analítica:', '').trim() : null;
			
			if (data && analitica) {

				let tipo = listaTipos.filter(tipoCusto => (tipoCusto.analiticas || []).filter(idAnalitica => idAnalitica == analitica).length)[0];
				
				data.setHours(data.getHours() + 12);

				let valor = item[19];
				if (data && valor && tipo) {
					let competencia = `${data.getFullYear()}${String(data.getMonth() + 1).padStart(2, '0')}`;

					if (!lista.filter(registro => registro.id_tipo == tipo.id && registro.competencia == competencia).length) {
						lista.push({
							id_tipo: tipo.id,
							competencia: competencia,
							valor: 0,
							id_usuario_inclusao: getState().auth.usuario.id,
							id_usuario_alteracao: getState().auth.usuario.id
						});
					}

					lista = lista.map(registro => {
						if (registro.id_tipo == tipo.id && registro.competencia == competencia) {
							return {
								...registro,
								valor: registro.valor + valor
							};
						} else {
							return registro;
						}						
					});		
				} else {
					console.log(item);
				}
			}
		}

		await axios.post(`${consts.API_URL}/custoImportacaoMes`, {
			lista: lista
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaMetaApuracao());
		}).catch(e => {
			setErroAPI(e);
		});

		dispatch(setAguardando(false));

	}
}

export function imprimir(registro) {
	return (dispatch, getState) => {
		
		axios.post(`${consts.API_URL}/metaManutencaoConfiguracao/imprimir`, {
			...registro,
			datahora_emissao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
		})
		.then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});
	}
}