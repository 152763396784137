const INITIAL_STATE = {
	aguardando: false,
	listaAno: [
		{ id: 2025, valor: '2025' },
		{ id: 2026, valor: '2026' },
		{ id: 2027, valor: '2027' }
	],
	listaMeses: [
		{ id: '01', valor: 'Janeiro' },
		{ id: '02', valor: 'Fevereiro' },
		{ id: '03', valor: 'Março' },
		{ id: '04', valor: 'Abril' },
		{ id: '05', valor: 'Maio' },
		{ id: '06', valor: 'Junho' },
		{ id: '07', valor: 'Julho' },
		{ id: '08', valor: 'Agosto' },
		{ id: '09', valor: 'Setembro' },
		{ id: '10', valor: 'Outubro' },
		{ id: '11', valor: 'Novembro' },
		{ id: '12', valor: 'Dezembro' }
	],
	listaCustoFolha: [],
	listaCustoFrota: [],
	listaCustoOutrasDespesas: [],
	listaOutrasDespesas: [],
	listaCustoAbastecida: [],
	listaMensal: [],
	listaKmRodado: [],
	listaVenda: []
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

		case 'DASHBOARD_CUSTO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};
	
		case 'DASHBOARD_CUSTO_FOLHA_LISTADO':
			return { ...state, listaCustoFolha: action.payload.data };
		
		case 'DASHBOARD_CUSTO_FROTA_LISTADO':
			return { ...state, listaCustoFrota: action.payload.data };
		
		case 'DASHBOARD_CUSTO_OUTRAS_DESPESAS_LISTADO':
			return { ...state, listaCustoOutrasDespesas: action.payload.data };

		case 'DASHBOARD_CUSTO_ABASTECIDA_LISTADO':
			return { ...state, listaCustoAbastecida: action.payload.data };

		case 'DASHBOARD_CUSTO_KM_RODADO_LISTADO':
			return { ...state, listaKmRodado: action.payload.data };

		case 'DASHBOARD_CUSTO_VENDA_LISTADO':
			return { ...state, listaVenda: action.payload.data };

        default:
            return state;
    }
}
